@use '../utility/reuseble' as *;
@use '../utility' as *;
.all_products_main {
  .tbody_classname {
    color: blue;
    td {
      font-size: 16px !important;
      padding: 10px !important;
    }
  }
  .photo_section {
    background-color: var(--color-gray);
    border-radius: 5px;
    .photo_card {
      display: flex;
      flex-wrap: wrap;
      margin: 2px 2%;
      position: relative;
      text-align: center;
      background-color: #fff;
      border-radius: 5px;
      border: 1px solid #dfe3e6;

      .customized_card {
        border-radius: 8px;
        padding: 0.5rem;

        &:hover {
          box-shadow: 20px 50px 50px -64px #1193ff;
        }
        .hover_div {
          border-radius: 5px;

          &:hover {
            .product_img {
              transform: scale(1.3);
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
  .question_wrapper {
    border: 1px solid #e6e3e3;
  }
  .box_main {
    background-color: var(--color-white);
    border-radius: 16px;
    height: 100%;
  }
  .header_top {
    
    padding: 0.2rem 0.6rem;
    border-bottom: 1px solid var(--color-gray-4);
    display: flex;
    align-items: center;
    h3 {
      font-size: var(--font-21);
      @extend %fw-500;
      color: var(--color-navy-2);
      padding: 0.5rem 0;
      font-family: var(--font-family-2);
      @include mediaMax(lg) {
        font-size: 1.2rem;
        padding: 0.3rem 0;
      }
    }
  }

  .text_message {
    align-items: baseline;
    flex-direction: column;
  }
  .middle_main {
    padding: 0.6rem;

    .search_main {
      border: 1px solid var(--color-gray-4);
      border-radius: 28px;
      padding: 0.3rem;
      display: flex;
      align-items: center;
      img {
        min-width: 24px;
      }
      @include mediaMax(md) {
        margin-bottom: 1rem;
      }
      input {
        &::-webkit-input-placeholder {
          color: var(--color-gray-5);
        }
        &:-ms-input-placeholder {
          color: var(--color-gray-5);
        }
        &::placeholder {
          color: var(--color-gray-5);
        }
      }
    }
    .check_box {
      width: 22px;
      height: 22px;
      cursor: pointer;
      border: 1px solid #19191b;
      padding-right: 1rem;
    }
    .checkbox_label {
      padding-left: 0.5rem;
    }
    .category_options {
      cursor: pointer;
    }
    .border-searchbox {
      height: 56px;
      width: 704px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #ccd2d6;
      border-radius: 28px;
    }
    .nuber_text {
      font-size: var(--font-14);
      color: var(--color-gray-6);
      font-family: var(--font-family-2);
      @extend %fw-500;
      margin: 1rem 0;
    }
    .products_table {
      &__table-wrapper {
        margin-bottom: 1.5rem;
        @include mediaMax(lg) {
          overflow-x: scroll;
          overflow-y: hidden;
        }
      }
      &__table-actions {
        margin-bottom: var(--gutter-bottom);
        @include mediaMax(md) {
          text-align: center;
        }
        & .btn {
          padding: 1rem 7.5rem;
        }
      }
      &__title {
        margin-left: 1rem;
        & h5 {
          font-size: var(--font-18);
          color: var(--color-black);
          @extend %fw-500;
        }
      }
      &__thead {
        background-color: #ddd;
        border-top: 0px;
        border-bottom: 0px;
      }
      &__theading {
        padding: 1rem 0.6rem !important;
        font-size: var(--font-16);
        color: var(--color-navy-2);
        font-family: var(--font-family-2);
        text-align: left;
        vertical-align: top;
        @extend %fw-500;
        position: relative;
        img {
          position: absolute;
          right: 0;
          top: 20px;
          border-right: 2px solid var(--color-gray-4);
          padding-right: 1rem;
          padding-left: 0.5rem;
        }
      }
      &__tbody {
        & tr {
          border-bottom: 1px solid hsla(240, 3%, 12%, 0.1);
        }
        & td {
          padding: 0.6rem 1rem;
          font-size: var(--font-16);
          color: var(--color-navy-2);
          @extend %fw-400;
          text-align: left;
          vertical-align: middle;
          .inactive_text {
            background-color: var(--color-red-light);
            color: var(--color-red);
            padding: 0.3rem 1rem;
            border-radius: 8px;
          }
          .active_text {
            background-color: var(--color-green-light);
            color: var(--color-green);
            padding: 0.3rem 1rem;
            border-radius: 8px;
          }
        }
      }
      &.banner_table {
        table {
          @include mediaMax(xxl) {
            min-width: 1200px;
          }
          .products_table__theading {
            i {
              @include mediaMin(xxl) {
                right: -9px;
              }
            }
          }
        }
      }
    }
  }
  .filter_text {
    color: var(--color-navy-2);
    font-family: var(--font-family-2);
    font-size: var(--font-16);
  }
  .filter_btn {
    width: 100%;
    min-width: 190px;
    border: 1px solid var(--color-gray-4);
    text-align: left;
    border-radius: 40px;
    box-shadow: none;
    color: var(--color-navy-2);
    font-family: var(--font-family-2);
    font-size: var(--font-16);
    padding: 1rem 1.25rem;
    @include mediaMax(md) {
      padding: 0.7rem 1.25rem;
    }

    &::after {
      background: url(../../images/chevron-down.svg);
      background-repeat: no-repeat;
      content: '';
      border: 0;
      width: 21px;
      height: 21px;
      position: absolute;
      right: 16px;
      top: 17px;
      @include mediaMax(md) {
        top: 10px;
      }
    }
  }
  .hover_effect {
    color: var(--color-navy-2);
    font-family: var(--font-family-2);
    font-size: var(--font-16);
    &:focus {
      background-color: var(--color-sky);
      border-radius: 6px;
      color: var(--color-blue-navy-2);
    }
    &:hover {
      background-color: var(--color-sky);
      border-radius: 6px;
      color: var(--color-blue-navy-2);
    }
    &.active {
      background-color: var(--color-sky);
      border-radius: 6px;
      color: var(--color-blue-navy-2);
    }
  }
  .order_id_main {
    display: flex;
    align-items: center;
    @include mediaMax(sm) {
      flex-direction: column;
      text-align: right;
    }
    div {
      border-right: 1px solid var(--color-gray-4);
      padding-left: 15px;
      padding-right: 15px;
      @include mediaMax(sm) {
        border-right: 0;
        padding: 0;
        width: 100%;
        margin: 5px 0;
      }
      &:last-child {
        border-right: 0;
      }
    }
    p {
      color: var(--color-navy-2);
      font-family: var(--font-family-2);
      font-size: var(--font-16);
      @extend %fw-500;
      a {
        color: var(--color-primary);
        margin-left: 10px;
        &.status_text {
          background-color: var(--color-green-light);
          color: var(--color-green);
          border-radius: 30px;
          padding: 5px 20px;
        }
      }
    }
  }
  .customer_title_1 {
    color: var(--color-navy-2);
    font-size: var(--font-16);
    font-family: var(--font-family-2);
    @extend %fw-500;
  }
  .customer_title_2 {
    color: var(--color-navy-2);
    font-size: var(--font-16);
    @extend %fw-400;
    word-break: break-word;
  }
  .order_header_main {
    display: flex;
    @include mediaMax(md) {
      flex-direction: column;
    }
    h3 {
      @include mediaMax(md) {
        width: 100%;
        text-align: left;
      }
    }
  }

  .address_card {
    padding: 20px;
    cursor: pointer;
    background-color: #e9ecef;
    border-radius: 16px;
  }
  .address_card h2 {
    color: #021d33;
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  .address_card h2 span {
    background: #dcefff;
    margin-left: 10px;
    padding: 8px 15px;
    color: #088fff;
    font-family: 'dm_sansmedium';
    font-size: 12px;
    border-radius: 32px;
  }
  .address_card p.text_des {
    color: #021d33;
    font-size: 18px;
    font-weight: 400;
  }
  .address_card:hover {
    box-shadow: 20px 50px 50px -64px #1193ff;
  }
}
