@use '../utility/reuseble' as *;
@use '../utility' as *;

#mainNav {
  .custom_navbar_collapse {
    overflow: auto;
    max-height: 75vh;
    .custom_nav {
      .custom_nav_item {
        .custom_nav_link {
          cursor: pointer;
        }
      }
      & > .custom_nav_item {
        &.dropdown {
          & > .custom_nav_link {
            position: relative;
            min-width: 2.813rem;
            @include mediaMin(lg) {
              border-radius: 30px;
            }
            &:hover {
              @include mediaMin(lg) {
                background-color: var(--color-gray);
              }
            }
            &.active {
              @include mediaMin(lg) {
                background-color: var(--color-gray);
              }
            }
            &:after {
              float: right;
              width: auto;
              content: '\f105';
              border: none;
              font-family: 'FontAwesome';
            }
            @include mediaMin(lg) {
              min-width: 0;
              color: var(--color-black);
              &::after {
                width: 1.313rem;
                text-align: center;
              }
            }
            img {
              border-radius: 100%;
              @include mediaMax(lg) {
                width: 40px;
              }
            }
          }
          &.show {
            & > .custom_nav_link {
              &:after {
                content: '\f107';
              }
            }
          }
          .custom_dropdown_menu {
            & > .custom_dropdown_item {
              & > .dropdown_message {
                overflow: hidden;
                max-width: none;
                text-overflow: ellipsis;
                @include mediaMin(lg) {
                  max-width: 18.75rem;
                }
              }
            }
          }
        }
      }
    }
    .custom_navbar_sidenav {
      .custom_nav_link_collapse {
        &:after {
          float: right;
          content: '\f107';
          font-family: 'FontAwesome';
        }
        &.collapsed {
          &:after {
            content: '\f105';
          }
        }
      }
      .sidenav_second_level {
        & > li {
          & > a {
            padding-left: 1em;
          }
        }
      }
      .sidenav_third_level {
        & > li {
          & > a {
            padding-left: 2em;
          }
        }
      }
      @include mediaMin(lg) {
        position: absolute;
        top: 0;
        left: 0;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-top: 56px;
      }
    }
    .sidenav_toggler {
      display: none;
    }

    @include mediaMin(lg) {
      overflow: visible;
      max-height: none;
      background-color: var(--color-white);
      padding: 0.5rem 2rem;
    }
  }
  &.top_main {
    background-color: var(--color-lovelymeds-theme);
    .sidenav_toggler {
      display: none;
    }
    &.nav_bar_main {
      .sidenav_toggler {
        background-color: var(--color-lovelymeds-theme);
      }
    }
    .custom_navbar_sidenav {
      @include mediaMin(lg) {
        height: calc(100vh - 112px);
        overflow-y: auto;
        overflow-x:hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    .sidenav_toggler {
      @include mediaMin(lg) {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-top: calc(100vh - 56px);
      }
      .custom_nav_item {
        @include mediaMin(lg) {
          width: 300px;
          padding: 0;
        }
        .custom_nav_link {
          @include mediaMin(lg) {
            padding: 1em;
          }
        }
      }
    }
    .logo_main {
      .logo_2 {
        @include mediaMax(lg) {
          display: none;
        }
      }
    }
  }
  &.nav_bar_main {
    .custom_navbar_collapse {
      .custom_navbar_sidenav {
        @include mediaMax(lg) {
          padding: 1rem 1rem 0rem 1rem;
        }
        .custom_nav_link_collapse {
          &:after {
            color: var(--color-gray-2);
          }
        }
        & > .custom_nav_item {
          & > .custom_nav_link {
            &:hover {
              color: var(--color-white);
            }
            @include mediaMin(lg) {
              padding: 0.8rem 1rem;
              font-size: var(--font-18);
            }
          }
          @include mediaMin(lg) {
            width: 300px;
            padding: 0;
          }
        }
      }
      .custom_nav {
        & > .custom_nav_item {
          &.dropdown {
            & > .custom_nav_link {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .custom_navbar_collapse {
    .custom_navbar_sidenav {
      .sidenav_second_level {
        padding-left: 0;
        > li {
          > a {
            display: block;
            padding: 0.5em 0;
            &:focus {
              text-decoration: none;
            }
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
      .sidenav_third_level {
        padding-left: 0;
        > li {
          > a {
            display: block;
            padding: 0.5em 0;
            &:focus {
              text-decoration: none;
            }
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  &.nav_bar_main {
    .custom_navbar_collapse {
      .custom_navbar_sidenav {
        li {
          &.active {
            a {
              @include mediaMin(lg) {
                color: var(--color-white) !important;
              }
            }
          }
        }
        li.active {
          a {
            &:focus {
              @include mediaMin(lg) {
                color: var(--color-white);
              }
            }
            &:hover {
              @include mediaMin(lg) {
                color: var(--color-white);
              }
            }
          }
        }
        @include mediaMin(lg) {
          background-color: var(--color-lovelymeds-theme);
          padding-top: 1.4rem;
        }

        > .custom_nav_item {
          .sidenav_second_level {
            > li {
              background-color: var(--color-lovelymeds-theme);
              > a {
                color: var(--color-gray-2);
                &:focus {
                  color: var(--color-white);
                }
                &:hover {
                  color: var(--color-white);
                }
                @include mediaMin(lg) {
                  padding-left: 3.85rem;
                }
              }
              @include mediaMin(lg) {
                width: 15rem;
                min-width: 100%;
              }
            }
            @include mediaMin(lg) {
              padding-left: 0;
              list-style: none;
            }
          }
        }
      }
    }
  }
  @include mediaMin(lg) {
    .logo_main {
      width: 300px;
      .logo_1 {
        display: inline-block;
      }
      .logo_2 {
        display: none;
      }
    }
  }
  span {
    &.nav_link_text {
      margin-left: 15px;
    }
  }
  .user_text {
    font-size: var(--font-16);
    @extend %fw-500;
    color: var(--color-black);
    font-family: var(--font-family-2);
    @include mediaMax(lg) {
      color: inherit;
    }
  }
  .custom_dropdown_text {
    font-size: var(--font-16);
    @extend %fw-500;
    color: var(--color-navy-2);
    font-family: var(--font-family-2);
    padding: 5px 16px;
    @include mediaMin(lg) {
      min-width: 176px;
    }
  }
}

.topper_main {
  padding-top: 3.9rem;
  margin-bottom: 3.5rem;
  .no_access{
    @extend %fw-400;
    font-size: var(--font-28);
    color: var(--color-black);
    position: fixed;
    z-index: 1050;
    top: 120px;
    left: 330px;
    @include mediaMax(lg) {
      top: 140px;
      left: 10px;
      z-index: 0;
      position: absolute;
    }
  }
  .top_title {
    @extend %fw-400;
    font-size: var(--font-21);
    color: var(--color-black);
    position: fixed;
    z-index: 1050;
    top: 26px;
    left: 330px;
    @include mediaMax(lg) {
      top: 80px;
      left: 10px;
      z-index: 0;
      position: absolute;
    }
  }
  &.sidenav_toggled {
    .top_title {
      left: 80px;
    }
    #mainNav {
      &.top_main {
        .sidenav_toggler {
          overflow-x: hidden;
          width: 55px;
        }
        .logo_main {
          width: auto;
          .logo_1 {
            display: none;
          }
          .logo_2 {
            display: inline-block;
          }
        }
        .sidenav_toggler {
          .custom_nav_item {
            width: 3.438rem !important;
          }
        }
      }
    }
    .custom_navbar_sidenav {
      @include mediaMin(lg) {
        // overflow:inherit !important;
      }
      width: 55px;
      .nav_link_text {
        display: none;
        @include mediaMax(lg) {
          display: inline-block;
        }
      }
      .custom_nav_item {
        width: 55px !important;
        white-space: nowrap;
        &:after {
          display: none;
        }
      }
      .custom_nav_link_collapse {
        @include mediaMin(lg) {
          &:after {
            display: none;
          }
        }
      }
    }
  }
  .search_click {
    @include mediaMax(lg) {
      display: none;
    }
  }
  .search_input {
    display: none;
    @include mediaMax(lg) {
      display: block;
    }
    &.show {
      min-width: 13rem;
      display: block;
    }
  }
  .search_btn {
    border-radius: 0px 6px 6px 0px;
    margin-left: -5px;
    padding: 11px 20px;
  }
}
