@use '../utility/reuseble' as *;
@use '../utility' as *;

.dashboard_main {
  .box_main {
    background-color: var(--color-white);
    padding: 1.5rem;
    border-radius: 16px;
    display: flex;
    height: 100%;

    span {
      border-radius: 100%;
      display: flex;
      margin-right: 2rem;
      padding: 1rem;
      align-items: center;
      justify-content: center;
      min-width: 80px;
      height: 80px;
      text-align: center;

      &.bg1 {
        background-color: var(--color-blue-sky);
      }

      &.bg2 {
        background-color: var(--color-blue-sky-2);
      }

      &.bg3 {
        background-color: var(--color-yellow-light);
      }

      &.bg4 {
        background-color: var(--color-yellow-light-2);
      }

      &.bg5 {
        background-color: var(--color-green-light);
      }

      &.bg6 {
        background-color: var(--color-red-light);
      }
    }

    h2 {
      font-size: var(--font-48);
      @extend %fw-500;
      color: var(--color-navy-2);
      font-family: var(--font-family-2);

      @include mediaMax(xl) {
        font-size: 2rem;
      }
    }

    p {
      font-size: var(--font-24);
      @extend %fw-500;
      color: var(--color-gray-2);
      font-family: var(--font-family-2);

      @include mediaMax(xl) {
        font-size: 1.5rem;
      }
    }
  }

  .box_main_2 {
    background-color: var(--color-white);
    border-radius: 16px;
    height: 100%;

    .header_top {
      padding: 0.5rem 1.5rem;
      border-bottom: 1px solid var(--color-gray-4);
      display: flex;
      align-items: center;

      h3 {
        font-size: var(--font-21);
        @extend %fw-500;
        color: var(--color-navy-2);
        padding: 1rem 0;
        font-family: var(--font-family-2);

        @include mediaMax(lg) {
          font-size: 1.5rem;
        }
      }

      .custom_nav_link {
        background-color: var(--color-gray);
        border-radius: 30px;
        padding: 0.8rem 1.5rem;

        &::after {
          display: none;
        }
      }

      .user_text {
        font-size: var(--font-18);
        @extend %fw-500;
        color: var(--color-navy-2);
        font-family: var(--font-family-2);

        @include mediaMax(lg) {
          font-size: 1rem;
        }
      }
    }

    .middle_main {
      padding: 1.5rem;
      display: flex;

      @include mediaMax(sm) {
        display: block;
        text-align: center;
      }

      h2 {
        font-size: var(--font-48);
        @extend %fw-500;
        color: var(--color-navy-2);
        font-family: var(--font-family-2);

        @include mediaMax(xl) {
          font-size: 2rem;
        }
      }

      h3 {
        margin-bottom: 1rem;
        font-size: var(--font-24);
        @extend %fw-500;
        color: var(--color-gray-2);
        font-family: var(--font-family-2);

        @include mediaMax(xl) {
          font-size: 1.5rem;
        }
      }

      p {
        font-size: var(--font-18);
        color: var(--color-navy-2);
        font-family: var(--font-family-2);

        @include mediaMax(xl) {
          font-size: 1rem;
        }
      }

      .square_box {
        height: 24px;
        min-width: 24px;
        border-radius: 5px;

        &.bg1 {
          background-color: var(--color-green);
        }

        &.bg2 {
          background-color: var(--color-green-light);
        }

        &.bg3 {
          background-color: var(--color-primary);
        }
      }
    }
  }
}

.email_send_btn {
  font-size: 10px;
}

.weightStats {
  justify-content: space-between;
}

.weight-input {
  padding: 5px !important;
  width: auto;
  text-align: center;
}

.countryFilter {
  width: 60%;
  display: flex;
  margin: 0 18%;
  margin-top: 40px;
}

.countryButton {
  padding: 8px 40px;
  border-radius: 5px;
  margin: 10px;
  border: 1px solid black;
  color: black;
}

.selectedCountry {
  background-color: #088fff;
  border: 1px solid #088fff;
  color: white;
  font-weight: 600;
}